.container {
    border: 1px outset var(--grey);
    border-radius: 8px;
    padding: 0.25em;
}

.container:hover {
    background-color: var(--light-grey);
}

.payeeName {
    font-size: 14px; 
    font-weight: 600;
    margin-left: 0.5em
}

@media(max-width: 700px){
    .payeeName {
        display: none;
    }
}