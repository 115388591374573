.container {
    display: inline-flex;
}

.button {
    height: 30px;
    width: 30px;
    background-image: var(--primary-gradient);
    color: white;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px outset;
    font-size: 25px;
    font-weight: 600;
    cursor: pointer;
}

.button:hover {
    filter: brightness(110%);
    transform: scale(1.2);
}
 
.button:active {
    transform: scale(1);
}

.input {
    width: 40px;
    text-align: center;
    border: none;
    border-bottom: 1px solid;
    margin: 0 5px
}

.error {
    animation: shake 0.7s;
}

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  } 