.container > svg {
    width: 30px;
    height: 30px;
    margin-left: 0.25em;
    padding: 5px;
    border-radius: 15px;
}

.container.darkTheme > svg {
    color: var(--dark-grey)
}

.container.lightTheme > svg {
    color: var(--white)
}

.container > svg:hover {
    cursor: pointer;
}
.container.lightTheme > svg:hover{
    background: var(--white);
    color: var(--grey);
}

.container.darkTheme > svg:hover {
    background: var(--light-grey);

}
