.container > input{
    border-radius: 0;
}
.container > input:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.bottomRow {
    display: flex;
}

.bottomRow input, .bottomRow select {
    border-radius: 0;
}

.bottomRow select {
    border-bottom-left-radius: 8px;
}

.bottomRow select.noZipCode {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.bottomRow input {
    border-bottom-right-radius: 8px;
}

.bottomRow input.noZipCode {
    display: none
}