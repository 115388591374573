.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    margin-top: 0.5em;
}

.innerContainer {
    display: flex;
    flex: 1;
    overflow-x: auto;
    max-width: 50%;
}
.tile {
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    background: var(--very-light-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 0px 2px var(--grey);
    user-select: none;
}

.tile.selected, .tile.selected:hover {
    background: var(--light-primary)
}

.tile:hover, .navButton:hover {
    filter: brightness(90%);
    border: 2px solid var(--light-primary);
}

.navButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px outset;
    background-color: white;
    padding: 0 1em;
    height: 40px;
    cursor: pointer;
    border-radius: 20px;
    margin: 0 4px;
}



