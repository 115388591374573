.container {
    background: var(--white);
    font-size: 16px;
    padding: 1em 0 1em 1em;
}

.container :global(.stack), .container :global(.bottom) {
    background: var(--primary);
}

.container a {
    font-weight: 600;
    color: var(--black)
}