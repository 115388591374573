.button {
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    background-color: var(--white);
    cursor: pointer;
}

.button:hover {
    filter: brightness(1.3)
} 
.label {
    font-size: 14px;
    font-weight: 600;
}