.invoiceForm {
    border: 1px outset var(--light-grey);
    padding: 0.5em;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 1px var(--secondary);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media(max-width: 700px) {
    .container {
        margin: 0 0.5em
    }
    .header {
        display: block;
        padding-top: 20px;
    }
    .header > div {
        margin-bottom: 20px;
    }
    .invoiceForm {
        margin: 0
    }
}