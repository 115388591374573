.container {
    min-width: 310px;
    width: fit-content;
    height: 480px;
    display: flex;
    justify-content: center;
}

.modal {
    padding: 8px
}
.modalInnerContainer {
    display: flex;
    justify-content: center;
    padding: 0;
}

.modalOverlay {
    padding: 1%
}

@media (max-width: 700px) {
    .modal {
        width: fit-content;
    }
}