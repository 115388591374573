.container {
    margin-bottom: 5px;
    width: 100%;
}

.inputContainer {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.left {
    margin-right: 5px;
}

.right {
    margin-left: 5px
}

.label {
    font-size: 14px;
}

.input {
    width: 100%;
    position: relative;
}
.iconButton {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.iconButton svg {
    color: var(--grey)
}

.iconButton svg:hover {
    background-color: var(--light-grey);
    border-radius: 50%;
}

