.container {
    text-align: right;
}

.heroSection {
    position: relative;
    z-index: 1; /* Ensure the container creates a stacking context */
}

.heroBackground {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/paydm%2Fmain%2Froseau2000x1500.jpg?alt=media&token=a1a7cfec-684e-4313-af72-92e3b6aefc35");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: polygon(0 0, 100% 0, 100% 30%, 0 100%);
    position: absolute;
    top: 0; /* Make sure it starts at the top of the section */
    left: 0;
    height: 50vh;
    width: 100%;
    z-index: -1; /* Push it behind other content */
}

.heroBackground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #27635466; /* White tint */
    z-index: 1; /* Ensures it layers above the background image */
}

.heroColumn {
    display: flex;
}

.heroText {
    font-size: 50px;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--primary);
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px black;
    padding-top: 40px;
        padding-bottom: 20px;
  }

.homePhone {
    height: 450px;
    width: auto;
    margin-left: 40px;
}

.imanuScan {
    border-radius: 16px;
    border: 1px outset;
    margin: 20px;
    box-shadow: 3px 3px 3px 1px var(--grey);
}
@media (min-width: 1400px){
    .heroColumn {
        align-items: center;
    }
    .homePhone {
        height: 600px
    }

    .heroText {
        font-size: 55px;
        padding: 0;
    }
}

@media (max-width: 1200px){
    .heroBackground {
        height: 30vh;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/paydm%2Fmain%2Froseau1400x1050.jpg?alt=media&token=f21b4146-f6a8-4810-8770-34c30f964ea1");
    }
}

@media (max-width: 700px) {
    .heroBackground {
        background-image: url("https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/paydm%2Fmain%2Froseau700x525.jpg?alt=media&token=799efad6-ec93-45be-868b-bf9b3e272448");
    }
    .heroColumn {
        display: block;
        text-align: center;
    }

    .heroText {
        font-size: 40px;
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .homePhone {
        margin: 0;
        height: auto;
        width: 70vw;
        max-width: 290px;;
        margin: auto;
    }
}