.container {
    display: flex;
    background: var(--light-grey);
    padding: 5px 1em 0 1em;
    align-items: center;
    min-height: 80px;
    height: fit-content;
    border-radius: 8px;
}
.innerContainer {
    display: flex;
    align-items: center;
    flex: 1
}

.clickable:hover {
    cursor: pointer;
    filter: brightness(90%);
}
.left {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--light-grey);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: fit-content
}

.description > div:first-child {
    font-weight: 600
}

.reason {
    font-size: 15px
}

.details{
    font-size: 0.7em;
    margin-bottom: 5px;
}

.right {
    margin-left: 5px
}