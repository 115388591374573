.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon img {
    height: 80px;
    width: auto;
}

.statusTitle {
    font-weight: 600;
}
.statusMessage {
    font-size: 0.8em;
    max-width: 300px;
}