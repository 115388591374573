.container {
    position: relative;
    width: fit-content;
    height: fit-content;
}
.innerContainer {
    height: 56px;
    width: 56px;
    min-height: 56px;
    min-width: 56px;
    border-radius: 50%;
    border: 1px solid var(--white);
    color: var(--white);
    background-color: var(--dark-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 0.9rem;
    font-size: 1rem;
    font-weight: 400;
    overflow: hidden;
    position: relative;
    user-select: none;
}

.innerContainer img {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
}

.innerContainer.medium {
    height: 80px;
    width: 80px;
    min-height: 80px;
    min-width: 80px;
    border-width: 3px;
    font-size: 2rem;
}

.innerContainer.big {
    height: 150px;
    width: 150px;
    min-height: 150px;
    min-width: 150px;
    border-width: 3px;
    font-size: 4rem;
}

.innerContainer.big svg{
    font-size: 50px;
}

.innerContainer.clickable {
    cursor: pointer;
}
.innerContainer.clickable:hover {
    filter: brightness(90%)
}

.showOnHover {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.showOnHover:hover {
    opacity: 100%;
}

.bottomRight {
    position: absolute;
    bottom: 8px;
    right: 8px;
}