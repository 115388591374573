.container {
    display: flex;
    justify-content: center;

    height: 480px;
    min-width: 310px;
    width: fit-content;
}

.container {
    height: auto;
    min-width: 310px;
    width: fit-content;

    display: flex;
    flex-direction: column;

    padding: 10px 20px 20px 20px;

    align-items: center;
    box-sizing: border-box;
    justify-content: center;
}

/* Modal styling */
.modal {
    padding: 8px;
}
.modalInnerContainer {
    padding: 0;
    display: flex;
    justify-content: center;
}
.modalOverlay {
    padding: 1%;
}

.confirmationTitle {
    font-size: 1.5em;
    margin-bottom: 0.75em;
}

.smsCodeInputs {
    display: flex;
    justify-content: space-between;
}

.smsCodeInputs input {
    width: 40px;
    height: 40px;

    margin-right: 8px;
    text-align: center;
    box-sizing: border-box;

    font-size: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.smsCodeInputs input:last-child {
    margin-right: 0;
}

.smsCodeInputs input:focus {
    outline: none;
    border-color: #007BFF;
    box-shadow: 0 0 2px 2px rgba(0, 123, 255, 0.2);
}


@media (max-width: 700px) {
    .modal {
        width: fit-content;
    }
    .container {
        padding: 10px;
    }
    .smsCodeInputs input {
        width: 35px;
        height: 35px;
        font-size: 18px;
    }
}
