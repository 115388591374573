.container {
    background: var( --very-light-grey);
    border-radius: 8px;
    width: 100%;
}

.container > div {
    border-radius: 8px;
    border: 0.5px solid var(--grey);
}
.container input {
    padding: 10px 16px;
    font-size: 16px;
    background: transparent;
}