.container {
    padding: 5px;
    border-radius: 8px;;
}
.container:hover {
    background-color: var(--light-grey);
}

.total {
    font-size: 13px;
}