.container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}

.container svg {
    font-size: 50px;
    color: var(--white)
}

.innerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100%; 
    background-color: inherit;
    padding: 1em;
    width: 100%;
}
.iconContainer {
    background-color: var(--light-grey);
    height: 70px;
    width: 70px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    border-radius: 70px;
    margin: 1em 0;
}

.message {
    font-size: var(--strong-font);
    text-align: center;
    max-width: 100%;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.container button {
    min-width: 200px;
    font-weight: 600;
}
.container.success {
    background: var(--light-success-green);
}

.container.success .iconContainer {
    background-color: var(--dark-success-green);
}


.container.success .message {
    color: var(--dark-success-green);
}

.container.error {
    background: var(--light-error-red);
}

.container.error .iconContainer {
    background-color: var(--dark-error-red);
}


.container.error .message {
    color: var(--dark-error-red);
}

.container.warning {
    background: var(--warning-yellow);
}

.container.warning .iconContainer {
    background-color: var(--dark-warning-yellow);
}


.container.warning .message {
    color: var(--dark-warning-yellow);
}

@media(max-width: 700px) {
    .container {
        font-size: var(--small-font);
    }
}
