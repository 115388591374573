.container {
    height: fit-content;
    margin: 1em 0;


    /* form */
    width: 100%;
    padding: 2em;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--white);
    scroll-behavior: smooth;
}

.container :global(.h2){
    text-align: center;
}
.resetPassword {
    height: 50px;
    display: flex;
    align-items: flex-end;
}

.resetPassword > .anchorLink {
    margin-left: 3px;
  
}

.anchorLink {
    font-weight: 600;
    color: var(--info-blue)
}

.createAccountButton {
    margin-top: 0.5em;
}