.container {
    display: flex;
    align-items: center; 
    font-size: 10px;
    text-align:left;
    background-color: var(--very-light-grey);
    border: 2px solid var(--light-grey);
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    max-width: 250px;
}

.container.selected {
    border: 2px solid var(--dark-grey);
}
.container.editable:hover {
    transform: scale(1.1);
}
.imageContainer {
    min-height: 30px;
    min-width: 30px;
    height: 32px;
    width: 32px;
    border-radius: unset;
    border: 0;
}

.imageContainer > img {
    border-radius: unset;
    border: 0
}

.detailsContainer { 
    padding: 2px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.variantDetails {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.inactive {
    filter:grayscale(1);
    cursor:not-allowed;
    background-color: var(--grey);
}
.inactive.container:hover, .inactive.container img:hover {
    transform: none
}

@media (max-width: 450px) {
    .container {
        max-width: 32vw;
    }
}