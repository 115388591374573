.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.container.list {
  grid-template-columns: repeat(1, 1fr);
  gap: 0;
}
.item {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(max-width: 700px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}