.container input {
    padding: 6px 8px;
}

.container label {
    font-size: 12px;
    white-space: nowrap;
}

.container :global(.errorText) {
    font-size: 12px;
}

.price input {
    padding: 5px 8px;
}
.mainInputs {
    display: flex;
}

.mainInputs > div {
    padding-left: 5px;
}

.mainInputs > div:last-of-type {
    width: 100px
}

.quantityInputs {
    min-height: 61px;
    display: flex;
    align-items: center;
}
.cameraInput {
    margin-top: 17px;
}