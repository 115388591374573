.container {
    margin: 0.5em 0
}

.text {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    background-image:linear-gradient(var(--grey),var(--grey)),linear-gradient(var(--grey),var(--grey));
    background-size:45% 1px;
    background-position:center left,center right;
    background-repeat:no-repeat;
    text-transform: uppercase;
}