.container {
    display: inline-flex;
    flex-direction: column
}

.container span:hover {
    cursor: pointer;
    background: #8080803d
}

.top svg  {
    margin-top: 0;
    margin-bottom: -13px;
}

.bottom  svg{
    margin-top: -8px;
}

.top.center svg {
    margin-bottom: -18px;
}
.bottom.center svg {
    margin-bottom: 6px;
}

.hidden {
    visibility: hidden;
}