/* Customize the label (the container) */
.container {
    display: flex;
    align-items: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--very-light-grey);
    border-radius: 50%;
    border: 2px solid var(--success-green);
  }
  
  .inactive .checkmark{
    visibility: hidden
  }

  .inactive.container{
    cursor: not-allowed;
  }

  .checkmarkContainer{
    position: relative;
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 5px
  }
  /* On mouse-over, add a grey background color */
  .container.editable:hover input ~ .checkmarkContainer > .checkmark {
    background-color: var(--very-light-grey);
    cursor: pointer;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmarkContainer > .checkmark,  .container.editable input:checked ~ .checkmarkContainer > .checkmark {
    background-color: var(--very-light-grey);
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmarkContainer > .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 4.5px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--success-green);
  }