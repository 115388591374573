.modal {
    width: 300px;
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}