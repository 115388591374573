.container {
    transition: background-color .3s, box-shadow .3s;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.google {
    height: 50px;
    width: 210px;
    margin: 3px;
}

.google > img{
    height: 100%;
    width: 100%;
}
.google > img:hover {
    box-shadow: 20px 20px 50px 10px #adbfde inset;
}

.google > img:active {
    box-shadow: 20px 20px 50px 10px #d3d3d3 inset;
    filter: grayscale(25%);
}