.container {
    display: flex;
}

.container > div {
    margin-right: 5px;
    margin-bottom: 0;
    border-radius: 5px;
    background: var(--white);
}

.container > select {
    margin: 0;
    padding: 0.5em;
    border-radius: 4px;
}
.container input {
    border-color: var(--grey)
}