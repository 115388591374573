.innerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid var(--grey);
    border-radius: 8px;
    padding: 5px
}

.innerContainer:hover {
    background: var(--light-success-green);
}

.innerContainer input {
    flex: 1;
    border: 0;
    padding: 10px;
    cursor: pointer;
}


.innerContainer > button {
    margin-left: 10px
}

.copyLabel svg{
    font-size: 14px;
}